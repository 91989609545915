$primary-color: #007BFF;
$secondary-color: #FF8400;
$container-width: 1140px;

@mixin aHoverAnim($color) {
    a {
        color: $color;
        font-weight: 500;
        position: relative;
    }

    a::after {
        content: '';
        position: absolute;
        top: 25px;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        /* Thickness of the underline */
        background-color: $color;
        /* Color of the underline */
        transform: scaleX(0);
        /* Start hidden (0 width) */
        transform-origin: left;
        /* Start animation from the left */
        transition: transform 0.5s ease;
        /* Duration and easing of the animation */
    }

    a:hover {
        color: $color;
        text-decoration: none;
    }

    a:hover::after {
        transform: scaleX(1);
        /* Full width on hover */
    }
}

body {
    font-family: 'Afacad', sans-serif;
    font-size: 18px;
    line-height: 32px;
}

h1 {
    font-size: 70px;
    line-height: 1;
}

h2 {
    font-size: 56px;
    line-height: 1.14em;
    padding: 0;
    margin: 0;
}

p {
    font-size: 22px;
    line-height: 32px;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
    color: $secondary-color;
}

.container {
    max-width: $container-width;
    padding: 0 20px;
    margin: 0 auto;
}

.grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.grid-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.white-btn,
.primary-btn {
    display: inline-block;
    background: #fff;
    color: #000;
    font-size: 20px;
    padding: 18px 38px;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    transition: all 0.5s ease;
    border-radius: 15px;

}

.primary-btn {
    background-color: $primary-color;
    color: #fff;
    font-weight: bold;
}

.white-btn::after,
.primary-btn::after {
    content: '';
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"%3E%3Cpath d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/%3E%3C/svg%3E');
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    width: 1em;
    height: 1em;
    transition: all 0.5s ease;

}

.primary-btn::after {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"%3E%3Cpath fill="%23FFFFFF" d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/%3E%3C/svg%3E');
}

.white-btn:hover,
.primary-btn:hover {
    text-decoration: none;
    background: $secondary-color;
    color: #fff;
}

.white-btn:hover::after,
.primary-btn:hover::after {
    transform: translateX(5px);
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"%3E%3Cpath fill="%23FFFFFF" d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/%3E%3C/svg%3E');
}



.hero-bg-image {
    background-image: url('../../public/assets/images/hero-image.jpg'), linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    background-blend-mode: overlay;
    background-size: cover;
    background-repeat: no-repeat;
}

.site-header {
    position: sticky;
    top: 0;

    .beforeHeader {
        background: $primary-color;
        padding: 12px 0;

        .contact-info {
            display: flex;
            gap: 30px;

            .info {
                a {
                    display: flex;
                    gap: 10px;
                    color: #fff;
                    align-items: center;
                }
            }

            .info:nth-child(3) {
                margin-left: auto;
            }

            svg {
                width: 20px;
                height: 20px;
                fill: #fff;
            }
        }
    }
    
    .mainHeader.moved{
        background: #212529;
    }

    .beforeHeader.moved{
        position: absolute;
        visibility: hidden;
        z-index: -5;
    }
    
    .mainHeader {
        border-bottom: 1px solid #ffffff41;
        position: absolute;
        width: 100%;
        transition: all 0.5s ease;

        .logo {
            max-width: 128px;
        }

        .container {
            display: flex;
            justify-content: space-between;
            padding: 20px;
        }

        .menu {
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;
                gap: 20px;

                li {
                    @include aHoverAnim(#fff);
                }
            }
        }
    }

    .page-header{
        background-color: #000;
    }
}

.site-main {
    padding: 200px 0;
    color: #fff;

    h1 {
        margin: 0;
        padding: 0;
    }

    p {
        font-size: 22px;
        margin: 24px 0;
    }

    .grid-2 {
        align-items: center;
    }

    .calendly-booking {
        background-color: #fff;
        height: 500px;
        justify-self: right;
        overflow: hidden;
    }

}

.grow-your-business {
    padding: 150px 0;

    .grid-2 {
        align-items: center;

        p {
            margin: 20px 0;
        }
    }

    img {
        width: 100%;
        max-width: 500px;
    }
}

.what-we-deal-with {
    padding: 150px 0;
    background-color: #F5F5F5;

    h2 {
        text-align: center;
    }

    .grid-2 {
        gap: 30px;
        margin-top: 50px;

        .grid-item {
            background: #fff;
            padding: 40px;
            display: flex;
            box-sizing: border-box;
            gap: 20px;

            h3,
            p {
                margin: 0;
            }

            h3 {
                margin-bottom: 10px;
            }
        }
    }
}

.our-stats {
    padding: 70px 0;
    background-color: #212529;
    color: #fff;

    .grid-3 {
        gap: 100px;

        .stat {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            strong {
                font-size: 80px;
            }
        }
    }
}

.pricing {
    padding: 150px 0;

    h2 {
        text-align: center;
        margin-bottom: 52px;
    }

    .grid-3 {
        gap: 20px;

        h3 {
            font-size: 30px;
        }

        .pricing-plan {
            padding: 20px;
            border: 1px solid #e7e7e8;

            .price {
                display: block;
                margin-top: 50px;

                strong {
                    font-size: 80px;
                }
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    list-style: none;
                    /* Hide the default marker */
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' fill='%23007BFF'%3E%3Cpath d='M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-position: left center;
                    padding-left: 30px;
                    /* Adjust padding for SVG space */
                    background-size: 16px;
                    /* Adjust size if needed */
                }
            }

            .white-btn {
                margin-top: 20px;
                border: 1px solid #000;
            }

            .white-btn.active {
                border-color: $primary-color;
                background-color: $primary-color;
                color: #fff;
            }

            .white-btn.active::after {
                background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"%3E%3Cpath fill="%23FFFFFF" d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/%3E%3C/svg%3E');
            }

            .white-btn:hover {
                background: $secondary-color;
                border-color: $secondary-color;
            }
        }
    }
}

.site-footer {
    padding: 150px 0;

    .flex {
        display: flex;
        justify-content: space-between;

        .site-info {
            max-width: 500px;

            img {
                max-width: 200px;
            }

            p {
                font-size: 20px;
            }

            .contact-info {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    a {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        color: #000;
                    }

                    margin-bottom: 10px;


                }


                svg {
                    width: 20px;
                }
            }
        }

        .important-links {
            h3 {
                font-size: 26px;
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    @include aHoverAnim(#000);
                }
            }
        }

    }
}

.hamburger {
    display: none;
}

.page-top-section{
    padding-top: 50px;
}

@media (max-width:992px) {

    /* Hamburger icon */
    .hamburger {
        cursor: pointer;
        display: inline-block;
        position: relative;
        width: 30px;
        height: 24px;
    }

    .hamburger span {
        background-color: #fff;
        border-radius: 2px;
        display: block;
        height: 3px;
        margin: 5px 0;
        transition: 0.4s;
    }

    /* Cross icon animation */
    .hamburger.open span:nth-child(1) {
        transform: rotate(45deg) translate(5px, 6px);
    }

    .hamburger.open span:nth-child(2) {
        opacity: 0;
    }

    .hamburger.open span:nth-child(3) {
        transform: rotate(-45deg) translate(5px, -6px);
    }

    /* End of hamburger icon */

    .grid-2,
    .grid-3 {
        grid-template-columns: 1fr;
    }

    .grid-2>*:nth-child(2) {
        margin-top: 20px;
    }

    h1 {
        font-size: 48px;
    }

    h2 {
        font-size: 40px;
        ;
    }

    .site-header {
        .beforeHeader {
            display: none;
        }
    }

    .site-main {
        padding: 150px 0;

        .grid-2 {
            gap: 50px;
        }

        .calendly-booking {
            justify-self: center;
        }


    }

    .mainHeader {

        .menu {
            display: none;
            position: fixed;
            background: $primary-color;
            left: 0;
            top: 0;
            bottom: 0;
            padding: 40px;
            z-index: 999;
            
            ul {
                flex-direction: column;
                gap: 20px;

            }
        }

        .menu.opened {
            display: block;
        }
    }

    .what-we-deal-with {

        .grid-2 {
            .grid-item {
                padding: 40px 20px;
            }
        }
    }

    .our-stats {
        padding: 100px 0;
    }

    .grow-your-business,
    .what-we-deal-with,
    .pricing,
    .site-footer {
        padding: 50px 0;
    }

    .site-footer {
        .flex {
            flex-direction: column;
        }
    }
}